import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class JavaChatSystemService {


  messageDataObx = new Subject<any>()
  openChatObx = new Subject<any>()
  connectionObx = new Subject<any>()

  B2BimageData = new Subject<any>()





   url = "https://javachatsystem-1.onrender.com"
  //  url = "http://atlaseosvirtual-env-chat.eba-ihu5aw93.us-east-2.elasticbeanstalk.com"
  //  url = "http://localhost:8080"



  requestOptions = {
    method: "POST",
  };

  messagePortal!: EventSource

  constructor(private toasterService: ToastrService) { }

  
  async connect(username: string){
   // this.toasterService.info("Connecting to the chatting system", 'Info')
    let logger = await fetch(
      `${this.url}/server-socket/connect?name=${username}&app=atlas`,
      this.requestOptions
    ).then((data) => {
      if(data.status){
      //  this.toasterService.success("Chatting system connected successfully", 'Success')
        this.chatMessagePortal()
        this.connectionObx.next(true)

      }else{
        //this.toasterService.error("Connection failed, try again", 'Error')
      }
    })
    .catch((error) => {
      this.connectionObx.next(false)
     // this.toasterService.error("Chatting system connection failed, try again", 'Error')
    });

  


  }


  reconnect() {
    // Implement a retry logic, e.g., exponential backoff
   
    
    setTimeout(() => {
      let uniqueId = null;
      const dealer = window.localStorage.getItem('user')
      if (dealer) {
        let data = JSON.parse(dealer)
        uniqueId = data.id + data.full_name.replaceAll(' ', '');
        this.connect(uniqueId)
      }
      
      this.chatMessagePortal()
      
    }, 5000); // Retry after 5 seconds
  }


  async sendMessage(data: any){
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };


    const response = await fetch(
      `${this.url}/server-socket/send-message-portal`,
      requestOptions
    );
  }
  
  
  async logoutUser(username: string){
   // this.toasterService.info("Logging out from the chatting system", 'Info')
    let logger = await fetch(
      `${this.url}/server-socket/disconnect?name=${username}&app=atlas`,
      this.requestOptions
    );

    this.messagePortal.close()

    if (logger.status === 200) {
     // this.toasterService.success("You have been logged out from the chatting system successfully", 'Success')
    }else{
     // this.toasterService.error("Logout failed, try again", 'Error')
    }
  }



  async removeMessage(message: any){
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(message),
    };
    const response = await fetch(
      `${this.url}/server-socket/remove-message-portal`,
      requestOptions
    );
  }

  
  chatMessagePortal(){
    this.messagePortal = new EventSource(`${this.url}/server-socket/message-portal`, { withCredentials: true });
    
    this.messagePortal.onmessage = (e) => {
     /// console.log(e, 'on message evenevt')
    }
    
    this.messagePortal.addEventListener('message-portal-event', message => {
        let data = JSON.parse(message.data);   
        this.messageDataObx.next(data)
        // console.log(data, 'java chat messages')
    });

    this.messagePortal.onerror = (e) => {
    ///  this.reconnect();
    }
  }



  
}
