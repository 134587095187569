import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioServiceService {

  constructor() { }

  playAudio(): void {
    const audio = new Audio();
    audio.src = '../../../../assets/bell-tone.wav';
    audio.load();
    audio.play();
  }

}
