import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { HttpRequestsService } from 'src/app/core/services/http-requests.service'

import { ChatService } from 'src/app/core/services/chat.service'
import { TokenStorageService } from 'src/app/core/services/token-storage.service'
import { ToastrService } from 'ngx-toastr'
import { JavaChatSystemService } from 'src/app/core/services/java-chat-system.service';
import { AudioServiceService } from './core/services/audio-service.service'



declare var $: any
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'atlas-eos'
  userData: any
  resizedStatus = false
  @ViewChild('langcheck') langlang!: ElementRef
  @ViewChild('audioTag') private audioTag!: ElementRef

  uniqueUserId = ''

  constructor(
    private tokenStorage: TokenStorageService,
    private chatServer: ChatService,
    private javaChatSystem: JavaChatSystemService,
    private audioService: AudioServiceService
  ) {

    this.setLang()
  }





  ngOnDestroy() {
    let user = this.tokenStorage.getUniqueId()
    this.javaChatSystem.logoutUser(user)
  }
  



  ngOnInit(): void {

    

    let userData = this.tokenStorage.getUser()
    let role = userData.role
    this.uniqueUserId = this.tokenStorage.getUniqueId()

    if (userData) {
     /// this.redirectUrl()
       this.javaChatSystem.connect(this.uniqueUserId)
    }else{
      console.log("no login saw")
    }
  
    this.javaChatSystem.messageDataObx.subscribe(
      (data) => {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
            if(this.tokenStorage.getUniqueId() == element.to){
                this.javaChatSystem.removeMessage(element)
              //  this.audioTag.nativeElement.play()
              this.audioService.playAudio()
            }
        }
      }, 
      (error) => {})

      this.javaChatSystem.connectionObx.subscribe(
        (data) => {
          if(!data){
            setTimeout(() => {
              this.javaChatSystem.connect(this.uniqueUserId)
            }, 50000)
          }
        }, (error) => {})
  }


  
  
  redirectUrl() {
    let userData = this.tokenStorage.getUser();

    if(!userData){
      this.tokenStorage.signOut()
    }

    let role = userData.role;
    /// this.userId = userData.id;
    let dashActivate = userData.dash_activate;
    let postShowDashboard = userData.post_show_dashboard;

    switch (role) {
      case 1:
        // this.router.navigate(['/admin/dashboard'])
        window.location.href = '/admin/dashboard';
        break;

      case 2:
        // this.router.navigate(['/branch/dashboard'])
        window.location.href = '/branch/dashboard';

        break;

      case 3:
        // this.router.navigate(['/vendors/dashboard'])
        window.location.href = '/vendors/dashboard';

        break;

      case 4:
        if (dashActivate) {
          window.location.href = '/dealers/dashboard';
        } else {
          window.location.href = '/dealers/all-seminars';
        }

        if (postShowDashboard) {
          window.location.href = '/dealers/order-summary';
        } else {
          window.location.href = '/dealers/dashboard';
        }
        // this.router.navigate(['/dealers/dashboard'])
        break;
      case 5:
        window.location.href = '/inside-sales/dashboard';
        // this.router.navigate(['/inside-sales/dashboard'])
        break;
      case 6:
        window.location.href = '/outside-sales/dashboard';

        break;

      case 7:
        window.location.href = '/second-admin/dashboard';

        break;

      default:
        break;
    }
  }




  setLang() {
    setInterval(() => {
      if ($('#langcheck').width() > 220) {
        localStorage.setItem('lang', 'fr')
      } else {
        localStorage.setItem('lang', 'en')
      }
    }, 2000)
  }


  shad() {
    setInterval(() => {
      console.log(
        'lang jquery',
        $('#langcheck').html(),
        this.langlang.nativeElement,
      )
      let elem = this.langlang.nativeElement.innerText
      console.log('lang =', elem, elem == 'catch', $('#langcheck').innerText)
      if (elem == 'catch') {
        //     console.log('lang =', 'true');
        localStorage.setItem('lang', 'en')
      } else {
        //console.log('lang =', 'false');
        localStorage.setItem('lang', 'fr')
      }
    }, 1000)
  }
}
