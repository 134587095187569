import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse, HttpBackend } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs'
import { TokenStorageService } from './token-storage.service'
import { Router } from '@angular/router'
import { retry, catchError } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class HttpRequestsService {
  title: any
  private basePath = '/news_update'
  apiUrl = environment.url
  cartNum = new Subject<any>()

  fileUploadUrl: string = ''

  token!: any

  b2bUrl = 'https://b2b.atlastrailer.com/api/external/link?partNum='

  alert = {
    status: false,
    message: '',
    badge: 'info',
  }
  private httpClient!: HttpClient;


  constructor(
    public http: HttpClient,
    private tokenStorage: TokenStorageService,
    public router: Router,
    private httpBackend: HttpBackend,
    private toasterServer: ToastrService
  ) {
    this.token = this.tokenStorage.getToken()
    this.httpClient = new HttpClient(httpBackend);

  }


  
  getImageRequest(atlasId: string) {
    return this.httpClient
      .get<any>(environment.url + '/fetch-product-atlas-id/' + atlasId, httpOptions)
      .pipe(catchError((error) => this.handleErrorV2(error)))
  }


   // Error handling
  handleErrorV2(error: any): Observable<any> {
    let errorMessage = {
      status: false,
      success: false,
      status_code: 500,
      message: '',
      data: [],
      debug: [],
    }
    if (error.error instanceof ErrorEvent) {
      // Get client-side error

      errorMessage = {
        status: false,
        success: false,
        status_code: error.error.status,
        message: error.error.message,
        data: error,
        debug: [],
      }
    } else {
      // Get server-side error
      if (error.status_code == 401) {
        errorMessage = {
          status: false,
          success: false,
          status_code: 503,
          message: 'Server Unavailable',
          data: [],
          debug: error.error.debug,
        }
      }  else if (error.status_code == 403) {
        errorMessage = {
          status: false,
          success: false,
          status_code: error.status,
          message: error.error.response,
          data: [],
          debug: error.error.debug,
        }

        this.tokenStorage.signOut()
        setTimeout(() => {
          this.router.navigate(['/'])
        }, 2000)

      } else if (error.status_code == 400) {
        errorMessage = {
          status: false,
          success: false,
          status_code: error.status,
          message: error.error.response,
          data: [],
          debug: error.error.debug,
        }
      } else {
        errorMessage = {
          status: false,
          success: false,
          status_code: 0,
          message: 'Http Error Response',
          data: error,
          debug: [],
        }
      }
    }

    return throwError(errorMessage)
  }



  uploadFile(route: string, up: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.apiUrl + route, up)
        .toPromise()
        .then(
          (res) => {
            this.checkAuthorization(res)

            resolve(res)
          },
          (err) => {
            this.checkAuthorization(err)

            reject(err)
          },
        )
    })
  }

  httpPostRequest(route: string, data: any = false, error = true) {
    return new Promise((resolve) => {
      this.http.post(this.apiUrl + route, data, httpOptions).subscribe(
        (response: any) => {
          this.checkAuthorization(response)
          resolve(response)
        },
        (error) => {
          this.checkAuthorization(error)
          resolve(error)
        },
      )
    })
  }

  httpGetRequest(route: string, data: any = false, error = true) {
    return new Promise((resolve) => {
      this.http.get(this.apiUrl + route).subscribe(
        (response: any) => {
         // this.checkAuthorization(response)
          //console.log(response);
          resolve(response)
        },
        (error: any) => {
          this.checkAuthorization(error)
          //console.log(error);

          resolve(error)
        },
      )
    })
  }

  checkAuthorization(result: any) {

    if (
      result.status == 401 ||
      result?.error?.message == 'Unauthorized' ||
      result?.error?.message == 'Unauthenticated'
    ) {
      this.toasterServer.info('Session has Timed Out')
      this.tokenStorage.signOut()
      setTimeout(() => {
        this.router.navigate(['/'])
      }, 2000)
      
    }
  }

  handleError(
    message: any,
    nature = 'info',
    httpError: any = false,
    input: any = false,
  ) {
    switch (nature) {
      case 'success':
        break
      case 'info':
        break
      case 'danger':
        break
      case 'warning':
        break

      default:
        break
    }
  }
}
