// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // LIVE API WORKING

  ///// TEST API ///
   ///  url: 'http://127.0.0.1:8000/api',
  ///  superAdminurl: 'http://127.0.0.1:8000/super-admin-api',
  //  dealerUrl: 'http://127.0.0.1:8000/dealer-api',




  url: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/api',
  superAdminurl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/super-admin-api',
  vendorUrl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/vendor-api',
  adminUrl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/admin-api',
  dealerUrl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/dealer-api',

  insideSalesUrl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/inside-sales-api',
  outsideSalesUrl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/outside-sales-api',
  branchUrl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca/branch-api',



  webUrl: 'https://rc-api.virtual.atlastrailerbuyingshow.ca',

}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.




